<template>
    <page-view class="provideMater-all" v-infinite-scroll="loadScroll" :infinite-scroll-disabled="disabled"
        :infinite-scroll-immediate="false" :up-class="'provideMater-all'">
        <div class="searcr">
            <el-input placeholder="请输入公司名称" v-model.trim="input" @change="searchInput" clearable>
                <el-button slot="append" icon="el-icon-search" @click="searchInput"></el-button>
            </el-input>
        </div>
        <div class="spe-box">
            <div class="spe-item hiden">
                <div class="name">服务类型:</div>
                <div class="content">
                    <ul>
                        <li :class="{ active: textIndex === null }" @click="changeType('all')">
                            全部
                        </li>
                        <li v-for="(item, index) in textList" :key="index" :class="{ active: textIndex === index }"
                            @click="changeType(index)">
                            {{ item }}
                        </li>
                    </ul>
                </div>
            </div>

        </div>
        <div class="sort">
            <div class="sort-item">综合排序</div>
            <div class="sort-item">销量</div>
            <div class="sort-item">星级</div>
            <div class="sort-item">价格<i class="el-icon-arrow-down"></i></div>
            <div class="sort-item">
                <el-input v-model="price1" size="mini"></el-input>
                <div class="line"></div>
                <el-input v-model="price2" size="mini"></el-input>
                <el-button size="mini" class="confirm" @click="qujian">确认</el-button>
            </div>
        </div>
        <div class="goods-water">
            <div class="goods-content">
                <waterfall :img-width="isMobile ? 175 : 260" :water-data="waterDataCopy" :show-name="false"
                    @click-com="toComIndex" @on-click-water="toComIndex"></waterfall>
                <el-empty description="暂无数据" v-if="waterDataCopy.length < 1"></el-empty>
            </div>

            <center v-if="loading">
                <div class="bouncing-bar">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </center>
            <center v-if="noMore && waterDataCopy.length > 0">没有更多了</center>
        </div>
        <footer-com></footer-com>
    </page-view>
</template>
<script>
import Waterfall from "@/components/waterfall/waterfall.vue";
import { mapState } from "vuex";
export default {
    name: "PropsMakeAll",
    components: { Waterfall },
    data() {
        return {
            input: "", //搜索框
            price1: null, //价格start
            price2: null, //价格end
            typeIndex: null, //类型选中
            total: 0, //总条数
            current: 1, //当前页,
            selectId: null, //当前请求id
            waterData: [],
            waterDataCopy: [],
            loading: false,
            textList: ['珠宝类', '化妆品', '钟表类', '鞋服类', '餐饮类', '超市类', '数码类', '家电类', '运动类', '新能源类'],
            textIndex: null,
        };
    },
    computed: {
        ...mapState(["search", "screenWidth"]), //使用全局的分类
        noMore() {
            return this.waterData.length >= this.total;
        },
        disabled() {
            return this.loading || this.noMore;
        },
        isMobile() {
            if (this.screenWidth < 768) {
                return true;
            } else {
                return false;
            }
        },
    },
    watch: {
        search: {
            handler(newValue) {
                this.input = newValue.value;
                //修改网页标题
                let title = "百川商展-材料优选";
                document.title = this.input ? `${title}-${this.input}` : `${title}`;
            },
            deep: true,
        },
    },
    created() {
        this.input = this.$route.params.name;
        this.getexCellent();
    },

    activated() {
        this.input = this.$route.params.name;
    },
    methods: {
        qujian() {
            if (!this.price1 && !this.price2 || this.price1 == '' || this.price2 == '') {//为空，原数据填充
                this.waterDataCopy = JSON.parse(JSON.stringify(this.waterData))
            } else {
                let res = this.waterDataCopy.filter((item) => item.price >= this.price1 && item.price <= this.price2)
                this.waterDataCopy = res
            }
        },

        changeType(item) {
            if (item === "all") {
                //点击全部
                this.textIndex = null;
            } else {
                this.textIndex = item;
            }
            this.shuffle(this.waterDataCopy);
        },


        //获取公司列表
        async getexCellent() {
            const loading = this.$loading({
                lock: true,
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let result = await this.$api.selectSn({
                bc_company_type: 4,
                current: this.current,
                name: this.input,
                size: 10,
            });
            loading.close();
            this.waterData = result?.data;
            this.total = result.page.total;
            this.waterDataCopy = result?.data;
            if (this.$route.params.value) {
                this.textList.forEach((item,index)=>{
                    if (item == this.$route.params.value) {
                        this.textIndex = index
                    }
                })
            }
        },
        async loadScroll() {
            if (this.disabled) {
                return false; //没有更多了
            }
            this.loading = true;
            this.current++;
            let result = await this.$api.selectSn({
                bc_company_type: 4,
                current: this.current,
                name: this.input,
                size: 10,
            });
            this.loading = false;
            this.waterDataCopy.push(...result.data);
        },
        searchInput() {
            //修改网页标题
            let title = "百川商展-道具优选";
            document.title = this.input ? `${title}-${this.input}` : `${title}`;
            this.shuffle(this.waterDataCopy);
        },
        toComIndex(v) {
            const openRoute = this.$router.resolve({
                name: "ComIndex",
                params: { syscompanyid: v.syscompanyid },
            });
            window.open(openRoute.href, "_blank");
        },

        shuffle(arr) {
            var l = arr.length
            var index, temp
            while (l > 0) {
                index = Math.floor(Math.random() * l)
                temp = arr[l - 1]
                arr[l - 1] = arr[index]
                arr[index] = temp
                l--
            }
            this.waterDataCopy = []
            arr.forEach(element => {
                this.waterDataCopy.push(element)
            });
            // return arr
        }
    },
};
</script>
<style lang='scss' scoped>
.provideMater-all {
    flex: 1;
    background-color: #f5f5f5;

    .searcr {
        @include flexbox(row, center, center);
        margin: 20px 0;

        :deep(.el-input) {
            width: 40%;

            @media screen and (max-width: 1200px) {
                width: 90%;
            }

            .el-input__inner {
                box-shadow: 0px 20px 20px 0px rgba(70, 156, 76, 0.1);
                border-radius: 45px 0 0 45px;
                border: 1px solid #469c4c;
                border-right: none;
            }

            .el-input-group__append {
                background-color: #fff;
                color: #469c4c;
                font-size: 18px;
                border: 1px solid #469c4c;
                border-left: none;
                border-radius: 0 45px 45px 0;
            }
        }
    }

    .spe-box {
        width: $containerWidth;
        background-color: #fff;
        box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
        margin: auto;
        padding: 20px;
        box-sizing: border-box;

        @media screen and (max-width: 1200px) {
            width: 100%;
        }

        .spe-item {
            @include flexbox(row, space-between, flex-start);
            border-bottom: 1px dashed;
            padding: 10px 0;

            .name {
                font-size: 14px;
                margin: 5px 0;
            }

            .content {
                flex: 1;
                overflow: hidden;
                padding: 0 10px;

                >ul {
                    @include flexbox(row) {
                        flex-wrap: wrap;
                    }

                    li {
                        color: #979797;
                        font-size: 14px;
                        cursor: pointer;
                        margin: 5px 0;
                        margin-right: 18px;

                        &.active {
                            color: $primaryColor;
                        }
                    }
                }
            }

            .spe-item-btn {
                .el-button {
                    padding-left: 10px;
                    padding-right: 10px;

                    i {
                        margin-left: 5px;
                    }
                }
            }
        }

        .hiden {
            .content {
                height: 24px;
                overflow: hidden;
                transition: all 0.2s linear;
            }
        }
    }

    .sort {
        width: $containerWidth;
        background-color: #fff;
        box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
        margin: 10px auto;
        padding: 6px 20px;
        box-sizing: border-box;
        @include flexbox(row, flex-start, center);
        flex-wrap: wrap;

        @media screen and (max-width: 1200px) {
            width: 100%;
            padding-bottom: 0.3rem;
        }

        .sort-item {
            @include flexbox(row, flex-start, center);
            margin-right: 20px;
            color: #787878;
            font-size: 12px;

            @media screen and (max-width: 1200px) {
                margin-top: 0.2rem;
            }

            >.line {
                width: 20px;
                height: 1px;
                background-color: #787878;
                margin: 0 2px;
            }

            .el-input {
                width: 60px;
            }

            .confirm {
                margin-left: 10px;
            }
        }
    }

    .goods-water {
        padding: 50px 0;
        padding-top: 30px;
        background-color: #f8f9fb;

        .goods-content {
            width: $containerWidth;
            margin: auto;

            @media screen and (max-width: 1200px) {
                width: 100%;
            }
        }
    }

    .bouncing-bar {
        display: flex;
        justify-content: center;

        .line {
            height: 20px;
            width: 4px;
            background: #439c4c;
            margin-right: 4px;
            border-radius: 6px;
            animation: line-bounce 1s infinite ease-in-out;

            &:nth-child(1) {
                animation-delay: 0s;
            }

            &:nth-child(2) {
                animation-delay: 0.2s;
            }

            &:nth-child(3) {
                animation-delay: 0.3s;
            }

            &:nth-child(4) {
                animation-delay: 0.4s;
            }
        }
    }

    @keyframes line-bounce {
        0% {
            transform: scale(1);
        }

        20% {
            transform: scale(1, 2);
        }

        40% {
            transform: scale(1);
        }
    }
}
</style>